import Vue from 'vue'

export default {
  apiPrefix: '/api/v2/sites',
  getIncidentReports: function ({siteId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/incident_reports`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getIncidentReportLogs: function ({siteId, reportId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/incident_reports/${reportId}/logs`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
  getIncidentReportDownloadUrl: function ({siteId, reportId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/incident_reports/${reportId}/download_url`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
}