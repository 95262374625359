<template>
  <div
  class="store-update-manager"
  v-bind:class="{
    show: errMessage && !isLoginPage()
  }">
    <div class="message">
      <my-icon
        class="nosite-icon"
        v-bind:icon="'not-listed-location'"
        v-bind:width="60"></my-icon>
      <p class="nosite-txt">NO SITE</p>
      <div class="nosite-email" v-html="errMessage"></div>
      <p>
        <ion-button
        v-on:click="startCheckingMyPermissions"
        >
        Check Again</ion-button>
      </p>
      <div
      class="login-txt"
      v-on:click="goToLoginPage"
      >Logout
      <span>
      <my-icon
        v-bind:icon="'arrow-forward'"
        v-bind:width="18">
      </my-icon>
      </span>
      </div>
    </div>
  </div>
</template>

<script>
import MyIcon from '@/components/MyIcon'
export default {
  name: 'store-update-manager',
  components: {
    MyIcon
  },
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated
    },
    myEmail: function () {
      return this.$store.getters.myEmail
    },
    statusLoadLastAuthSession: function () {
      return this.$store.getters.statusLoadLastAuthSession
    },
    statusLogin: function () {
      return this.$store.getters.statusLogin
    },
    statusRefreshTokens: function () {
      return this.$store.getters.statusRefreshTokens
    },
    mySites: function () {
      return this.$store.getters.sites
    },
    selectedSiteId: function () {
      return this.$store.getters.selectedSiteId
    },
    checkPermissionRequest: function () {
      return this.$store.getters.checkPermissionRequest
    },
    onlineStatusChangeCounter: function () {
      return this.$store.getters.onlineStatusChangeCounter
    },
    apiRateLimitLockCounter: function () {
      return this.$store.getters.apiRateLimitLockCounter
    },
    statusGetSiteAllRelayStatus: function () {
      return this.$store.getters.statusGetSiteAllRelayStatus
    },
    siteRelaysStatus: function () {
      return this.$store.getters.siteRelaysStatus
    },
    statusGetSiteArmingSchedules: function () {
      return this.$store.getters.statusGetSiteArmingSchedules
    },
    statusMemberRoles: function() {
      return this.$store.getters.statusMemberRoles
    },
  },
  data: function () {
    return {
      errMessage: '',

      checkingingMyPermission$i: null,
      refreshingSite$i: null,
      refreshingSiteDevices$i: null,
      refreshingSiteEvents$i: null,
      refreshingRelayStatus$t: null,
    }
  },
  watch: {
    // WHEN - last id_token is retrieved from localStorage
    statusLoadLastAuthSession: function () {
      if (this.statusLoadLastAuthSession !== 'successful') return
      this.startCheckingMyPermissions()
    },
    // WHEN - login successful - got refresh_token and id_token
    statusLogin: function () {
      if (this.statusLogin !== 'successful') return
      this.startCheckingMyPermissions()
    },
    // WHEN - refresh id_token successful - got new id_token
    statusRefreshTokens: function () {
      if (this.statusRefreshTokens !== 'successful') return
      this.startCheckingMyPermissions()
    },
    // WHEN - selected site is changed
    selectedSiteId: function () {
      if (!this.selectedSiteId) return
      this.startRefreshingSite()
      this.startRefreshingSiteDevices()
      this.getSiteMembers()
      this.getArmingSchedule()
      this.resetCamerasConfigsFromProxy()
    },
    // WHEN - `check permission` is requested
    checkPermissionRequest: function () {
      this.startCheckingMyPermissions()
    },
    // WHEN - `get all relay status` is requested
    statusGetSiteAllRelayStatus: function () {
      if (this.statusGetSiteAllRelayStatus !== 'successful') return
      this.startCheckingRelaysTimer()
    }
  },
  created: function () {
    this.startCheckingMyPermissions()
    this.startRefreshingSite()
    this.startRefreshingSiteDevices()
  },
  beforeDestroy: function () {
    this.stopCheckingMyPermissions()
    this.stopRefreshingSite()
    this.stopRefreshingSiteDevices()
  },
  methods: {
    /////////////////////////////////////////////
    // CHECK - MY PERMISSIONS
    getMyProfile: function () {
      return this.$store.dispatch('getMyProfile')
    },
    getMySites: function () {
      return this.$store.dispatch('getSites')
    },
    checkPermission: async function () {
      // this.$tool.showSpinnerDialog()
      try {
        // check - authenticated
        if (!(await this.isAuthenticated())) return
        // authenticated
        await this.getMyProfile()
        await this.getMySites()
        await this.getMemberRoles()
        await this.getSiteMembers()
        // has my site(s)
        if (Object.keys(this.mySites).length > 0) {
          this.onHasValidPermission()
          // this.$tool.hideSpinnerDialog()
          return
        }
        // no my site(s)
        // ACTION - notAuthorized
        this.onNoSite()
        // this.$router.push({name: 'notAuthorized'})
      } catch (err) {
        this.$logger.error(err)
        // this.$tool.presentToast(`failed check permission `+ this.$tool.parseToStringify(err))
      }
      // this.$tool.hideSpinnerDialog()
    },
    startCheckingMyPermissions: function () {
      const interval = 1000 * 60 * 1 // every 1 minute
      this.stopCheckingMyPermissions()
      this.checkPermission()
      this.checkingingMyPermission$i = setInterval(() => {
        this.checkPermission()
      }, interval)
    },
    stopCheckingMyPermissions: function () {
      if (!this.checkingingMyPermission$i) return
      clearInterval(this.checkingingMyPermission$i)
      this.checkingingMyPermission$i = null
    },
    onHasValidPermission: function () {
      this.errMessage = ''
      // this.onNoSite() // just for testing
    },
    onNoSite: function () {
      this.errMessage = `${this.myEmail}<br>is not a member of any site.`
    },
    // CHECK - MY PERMISSIONS
    /////////////////////////////////////////////

    /////////////////////////////////////////////
    // GET - SITE
    getSite: function () {
      if (!this.selectedSiteId) return
      this.$store.dispatch('getSite', {siteId: this.selectedSiteId})
    },
    startRefreshingSite: function () {
      const interval = 1000 * 60 * 1 // every 1 minute
      this.stopRefreshingSite()
      this.getSite()
      this.refreshingSite$i = setInterval(() => {
        this.getSite()
      }, interval)
    },
    stopRefreshingSite: function () {
      if (!this.refreshingSite$i) return
      clearInterval(this.refreshingSite$i)
      this.refreshingSite$i = null
    },
    // GET - SITE
    /////////////////////////////////////////////

    /////////////////////////////////////////////
    // GET - SITE DEVICES
    // 1. Get device information to refresh live snapshot stream server token - should be every 10 minutes
    // 2. Get device information to change UX for offline/devices - should be every 1 minute
    getSiteDevices: function () {
      if (!this.selectedSiteId) return
      this.$store.dispatch('getSiteDevices', {siteId: this.selectedSiteId})
    },
    startRefreshingSiteDevices: function () {
      const interval = 1000 * 60 * 1 // every 1 minute
      this.stopRefreshingSiteDevices()
      this.getSiteDevices()
      this.refreshingSiteDevices$i = setInterval(() => {
        this.getSiteDevices()
      }, interval)
    },
    stopRefreshingSiteDevices: function () {
      if (!this.refreshingSiteDevices$i) return
      clearInterval(this.refreshingSiteDevices$i)
      this.refreshingSiteDevices$i = null
    },
    // GET - SITE DEVICES
    /////////////////////////////////////////////

    /////////////////////////////////////////////
    // GET - SITE ALL RELAY STATUS
    startCheckingRelaysTimer: function () {
      if (this.statusGetSiteAllRelayStatus === 'requested') return
      let timeRemain = null
      for(let k in this.siteRelaysStatus) {
        // INIT - status.
        const status = this.siteRelaysStatus[k]

        // CHECK - online & mode
        if (!status.isOnline) continue
        if (status.mode !== 'timer') continue

        // PREPARE - timer 남은시간 계산
        let requestedAtTimeStamp = new Date(status.requestedAt).getTime()
        let requestEndsAtTimeStamp = requestedAtTimeStamp + (status.modeDuration * 1000)
        let updatedAtTimeStamp = new Date(status.updatedAt).getTime()

        // "현재 시간 > timer 끝나는 시간" 이면 무시하기.
        if (updatedAtTimeStamp > requestEndsAtTimeStamp) continue

        const timeRemainTemp = requestEndsAtTimeStamp - updatedAtTimeStamp

        // SET - timeRemain as minimum
        if (timeRemain === null) timeRemain = timeRemainTemp
        else if (timeRemain > timeRemainTemp) timeRemain = timeRemainTemp
      }
      // ACTION - get All relay status after timer time.
      if (timeRemain === null) return
      this.cancelRefreshingRelayStatusTimeout()
      this.refreshingRelayStatus$t = setTimeout(() => {
        this.$store.dispatch('getSiteAllRelayStatus', {siteId :this.selectedSiteId})
      }, timeRemain + 1000)
    },
    cancelRefreshingRelayStatusTimeout: function () {
      if (!this.refreshingRelayStatus$t) return
      clearTimeout(this.refreshingRelayStatus$t)
      this.refreshingRelayStatus$t = null
    },
    // GET - SITE ALL RELAY STATUS
    /////////////////////////////////////////////

    /////////////////////////////////////////////
    // GET - SITE MEMBERS
    getSiteMembers: function () {
      if (!this.selectedSiteId) return
      return this.$store.dispatch('getSiteMembers', {
        siteId: this.selectedSiteId,
      })
    },
    // GET - SITE ALL RELAY STATUS
    /////////////////////////////////////////////

    /////////////////////////////////////////////
    // PAGE - login
    goToLoginPage: function () {
      this.$store.commit('REQUEST_LOGOUT')
    },
    isLoginPage: function () {
      return this.$route.path == '/login'
    },
    // PAGE - login
    /////////////////////////////////////////////

    /////////////////////////////////////////////
    // Get - arming schedule
    getArmingSchedule: function () {
      // action - api
      if (this.statusGetSiteArmingSchedules === 'requested') return
      this.$store.dispatch("getSiteArmingSchedules", {siteId : this.selectedSiteId})
    },
    // Get - arming schedule
    /////////////////////////////////////////////

    /////////////////////////////////////////////
    // Get - member roles
    getMemberRoles: function() {
      if (this.statusMemberRoles === 'requested') return
      return this.$store.dispatch("getMemberRoles")
    },
    // Get - member roles
    /////////////////////////////////////////////

    resetCamerasConfigsFromProxy: function () {
      // reset onvif cameras infos & config when change the site.
      this.$store.commit('RESET_SITE_CAMERAS_CAPABILITIES_FROM_PROXY')
      this.$store.commit('RESET_SITE_ONVIFS_FROM_PROXY')
      this.$store.commit('RESET_SITE_ONVIF_RELAY_FROM_PROXY')
    }
  }
}
</script>

<style scoped>
.store-update-manager {
  /* position */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  /* size */
  width: 100%;
  height: 100%;
  /* color */
  background-color: var(--ion-background-color);
  color: #000;
  /* appear */
  display: none;
}
.nosite-icon {
  width: 100%;
  opacity: .2;
}
.nosite-txt {
  font-size: 18px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 10px;
}
.nosite-email {
  opacity: .6;
}
.store-update-manager.show {
  /* position */
  z-index: 100000000;
  /* appear */
  display: table;
}
.login-txt {
  color: var(--ion-color-primary);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.store-update-manager .message {
  /* common */
  color: #000;
  /* font-size: 26px; */
  /* text */
  word-break: break-word;
  /* size */
  padding: 1em;
  /* alignment */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
</style>
