import Vue from 'vue'
import api from '@/apis/incidentReports'
import parser from '@/models/incidentReports'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_SITE_INCIDENT_REPORTS_REQUEST,
  GET_SITE_INCIDENT_REPORTS_SUCCESS,
  GET_SITE_INCIDENT_REPORTS_FAILURE,
  GET_SITE_INCIDENT_REPORT_LOGS_REQUEST,
  GET_SITE_INCIDENT_REPORT_LOGS_SUCCESS,
  GET_SITE_INCIDENT_REPORT_LOGS_FAILURE,
  GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_REQUEST,
  GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_SUCCESS,
  GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_FAILURE,
} from '@/store/mutation-types'
let initialState = {
  incidentReports: [],
  incidentReportLogs: {},
  incidentReportDownloadUrl: null,
  status: {
  },
}
// initial state
const state = Vue.util.extend({}, initialState)
// getters
const getters = {
  incidentReports: function (state) {
    return state.incidentReports
  },
  incidentReport: function (state, getters) {
    return function (reportId) {
      return getters.incidentReports.find(item => item.id == reportId);
    }
  },  
  incidentReportLogs: function (state) {
    return state.incidentReportLogs
  },
  incidentReportDownloadUrl: function (state) {
    return state.incidentReportDownloadUrl
  },
}
// actions
const actions = {
  getIncidentReports: function ({commit}, {siteId}) {
    commit(GET_SITE_INCIDENT_REPORTS_REQUEST)
    // API CALL
    return new Promise((resolve, reject) => {
      api.getIncidentReports({siteId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_INCIDENT_REPORTS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_INCIDENT_REPORTS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getIncidentReportLogs: function ({commit}, {siteId, reportId}) {
    commit(GET_SITE_INCIDENT_REPORT_LOGS_REQUEST)
    // API CALL
    return new Promise((resolve, reject) => {
      api.getIncidentReportLogs({siteId, reportId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_INCIDENT_REPORT_LOGS_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_INCIDENT_REPORT_LOGS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getIncidentReportDownloadUrl: function ({commit}, {siteId, reportId}) {
    commit(GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_REQUEST, {siteId})
    // API CALL
    return new Promise((resolve, reject) => {
      api.getIncidentReportDownloadUrl({siteId, reportId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}
// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_INCIDENT_REPORTS_REQUEST]: function (state) {
  },
  [GET_SITE_INCIDENT_REPORTS_SUCCESS]: function (state, {data}) {
    let parsedData = []
    data.forEach((item) => {
      parsedData.push(parser.parseIncidentReports(item))
    })
    parsedData.reverse()
    state.incidentReports = parsedData
  },
  [GET_SITE_INCIDENT_REPORTS_FAILURE]: function (state) {
  },
  [GET_SITE_INCIDENT_REPORT_LOGS_REQUEST]: function (state) {
  },
  [GET_SITE_INCIDENT_REPORT_LOGS_SUCCESS]: function (state, {data}) {
    let parsedLogs = parser.parseIncidentReportLogs(data)
    state.incidentReportLogs = parsedLogs
  },
  [GET_SITE_INCIDENT_REPORT_LOGS_FAILURE]: function (state) {
  },
  [GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_REQUEST]: function (state) {
  },
  [GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_SUCCESS]: function (state, {data}) {
    state.incidentReportDownloadUrl = data?.download_url
  },
  [GET_SITE_INCIDENT_REPORT_DOWNLOAD_URL_FAILURE]: function (state) {
  },
}
export default {
  state,
  getters,
  actions,
  mutations
}