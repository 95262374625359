import Peer from '@/plugins/peer'
import helper from '../models/siteCameraTool'

const WebrtcPlugin = {
  install (Vue, {store, router}) {
    let webrtc = {
      ////////////////////////////////////////////////
      ////////////////////////////////////////////////
      ////////////////////////////////////////////////
      // data
      audioContext: null,
      audioContextSender: null,
      audioWorkletNode: null,
      audioWorkletNodeSender: null,
      isAudioSenderConnected: false,
      bridges: [],
      cameras: [],
      kernelBufferSize: 1024 * 2, // 한번에 합칠 packet chunk
      channels: 1, // PCM은 mono channel이라 1로 하면 됨.
      senderSource: null,
      iceServers: null,
      streamStatus: null,
      streamTime: null,
      videoDetailsToHtml: ``,
      counter: null,
      isSupportedVideoDecoder: null,
      lastRequestedCameraId: null,
      configured: false,

      ////////////////////////////////////////////////
      ////////////////////////////////////////////////
      ////////////////////////////////////////////////
      // methods
      bridge: function (cameraId) {
        for (let i = 0; i < this.bridges.length; i++) {
          const bridge = this.bridges[i];
  
          for (let ii = 0; ii < bridge.cameras.length; ii++) {
            const camera = bridge.cameras[ii];
            if (camera.deviceId == cameraId) return bridge
          }
        }

        return {}
      },
      camera: function (cameraId) {
        for (let i = 0; i < this.bridges.length; i++) {
          const bridge = this.bridges[i];

          for (let ii = 0; ii < bridge.cameras.length; ii++) {
            const camera = bridge.cameras[ii];
            if (camera.deviceId == cameraId) return camera
          }
        }
        
        return {}
      },
      init: async function () {
        try {
          this.isSupportedVideoDecoder = await this.checkVideoDecoderSupport()
          await this.prepareWebrtcModules()
          await this.requestAllPeers()
        } 
        catch (err) {
          console.log(err);
        }
      },
      ////////////////////////////////////////////
      // peer
      makePeers: async function () {
        this.iceServers = await this.getIceServers()
        if (!this.iceServers) return
  
        for (const bridge of this.bridges) {
      
          for (let i = 0; i < bridge.peersIndex; i++) {
            try {
              this.makePeer(bridge, i)
            }
            catch (err) {
              console.log(err);
            }
          }
        }
      },
      makePeer: async function (bridge, i) {
        if (!this.isOkayToStartConnection(bridge)) return

        const peer = await new Peer({ 
          ice_servers: this.iceServers, 
          site_id: store.getters?.selectedSiteId, 
          bridge_id: bridge.deviceId,
        });

        peer?.set_on_connection_status_callback(async(e) => {
          if (e === "disconnected" || e === "failed" || e === "closed") {
            if (bridge?.reconnectTry > 10 || !store.getters?.isAppUse) return
            await this.resetAndReconnectPeers()
            bridge.reconnectTry++
          }
          else if (e === "connected" && i === 0) {
            bridge.reconnectTry = 0

            const {bridge: bridgeFromCamera} = this.getRequestBridgeAndCamera(store.getters?.currentPageDeviceId)

            if (bridgeFromCamera?.deviceId !== bridge?.deviceId) return
            
            peer.set_on_video_channel_connected_callback(async (e) => {
              // 현재 카메라를 보고 있고
              // 앱을 사용중이고
              // video channel이 연결되어 있다면...
              if (e && store.getters?.currentPageDeviceId) {
                // isAppUse가 null인 경우도 있음(default 값). 그럴 때는 stream 시도.
                if (store.getters?.isAppUse === false) return
                this.changeStream(store.getters?.currentPageDeviceId, store.getters?.currentPageDeviceId)
              }
          })
          }
        })
  
        await peer.connect()
  
        // add peer to peers
        if (i === 0) bridge.peers['front'] = peer
        else if (i === 1) bridge.peers['back'] = peer
      },
      makePeerCustom: async function (bridge, name) {
        if (!bridge || !name) return
        if (!this.isOkayToStartConnection(bridge)) return

        const peer = await new Peer({ 
          ice_servers: this.iceServers, 
          site_id: store.getters?.selectedSiteId, 
          bridge_id: bridge.deviceId,
        });

        peer?.set_on_connection_status_callback((e) => {
          if (e === "disconnected" || e === "failed" || e === "closed") {
            if (bridge?.reconnectTry > 10) return
            this.resetAndReconnectPeers()
            bridge.reconnectTry++
          }
        })
  
        await peer.connect()

        bridge.peers[name] = peer
      },
      getIceServers: async function () {
        let response = await store.dispatch('getWebrtcIceServers', {
          siteId: store?.getters?.selectedSiteId,
        })
  
        return response?.body?.iceServers
      },
      getCapabilities: async function (bridge, camera) {
        let capabilities = {}
        
        try {
          if (camera?.mountType !== 2) {

            await this.getSiteCameraOnvifProfilesFromProxy(bridge, camera)
            
            const profleIndex = store.getters?.camerasOnvifSelectedProfileIndex?.[camera?.deviceId]
            capabilities = store.getters?.camerasOnvifProfiles?.[camera?.deviceId]?.[profleIndex]
            
            // 실패시 camerasStreamCapabilities에서 가져옴
            // 이미 store에서 Profiles가 없으면 Stream Capabilities을 호출하는 루틴이 있음
            if (!capabilities) {
              capabilities = store.getters.camerasStreamCapabilities[camera?.deviceId]
            }
          }
          else {
            await this.getSiteCameraStreamCapabilitiesFromProxy(bridge, camera)
            capabilities = store.getters.camerasStreamCapabilities[camera?.deviceId]
          }
        } 
        catch (err) {
          console.log(err);
          // 혹시 실패했을 때 Stream Capabilities을 호출
          await this.getSiteCameraStreamCapabilitiesFromProxy(bridge, camera)
          capabilities = store.getters.camerasStreamCapabilities[camera?.deviceId]
        }

        return capabilities
      },
      getSiteCameraOnvifProfilesFromProxy: async function (bridge, camera) {
        return await store.dispatch('getSiteCameraOnvifProfilesFromProxy', {
          siteId: store.getters?.selectedSiteId,
          bridgeId: bridge.deviceId, 
          channel: camera?.channel, 
          deviceId: camera?.deviceId
        })
      },
      getSiteCameraStreamCapabilitiesFromProxy: async function (bridge, camera) {
        return await store.dispatch('getSiteCameraStreamCapabilitiesFromProxy', {
          siteId: store.getters?.selectedSiteId,
          bridgeId: bridge.deviceId, 
          channel: camera?.channel, 
          deviceId: camera?.deviceId
        })
      },
      clearAllPeers: async function () {
        if (!(this.bridges instanceof Array)) return
        for (let i = 0; i < this.bridges.length; i++) {
          const bridge = this.bridges[i];
  
          await this.clearPeers(bridge)
        }
      },
      clearPeers: async function (bridge) {
        if (!bridge) return
        this.resetAudioPacket()
        await this.clearBridgePeers(bridge)
      },
      clearPeerCustom: async function (bridge, name) {
        if (!bridge || !name) return
        await bridge.peers?.[name]?.close()
        delete bridge.peers?.[name]
      },
      isOkayToStartConnection: function (bridge) {
        if (!bridge) return false
        // 1. CHECK - Bridge network
        if (!bridge.isOnline) return false
        // 2. CHECK - Bridge FW
        if (!bridge.isFwSupportWebrtc) return false
  
        return true
      },
      requestAllPeers: async function () {
        this.getBridgeListFromDeviceList()
        await this.makePeers()
      },
      resetAndReconnectPeers: async function () {
        try {
          await this.clearAllPeers()
          await this.requestAllPeers()
        } 
        catch (err) {
          console.log(err);
        }
      },
      // peer
      ////////////////////////////////////////////
  
      ////////////////////////////////////////////
      // prepare modules
      prepareWebrtcModules: async function () {
        // init workers
        this.initVideoWorker()
        this.initAudioWorker()
  
        // add audio context
        await this.addAudioContext()
        await this.addAudioContextSender()
      },
      initVideoWorker: function () {
        const updateVideoDetails = Vue.tool.throttle((message) => {

          this.streamStatus = message.data?.status;
          this.streamTime = message?.data?.absolute_dts;

          if (!store.getters?.showWebrtcVideoDetails) return;

          this.videoDetailsToHtml = `
            <strong>Codec:</strong> ${message.data?.config?.codec || ""}<br>
            <strong>Resolution:</strong> ${message.data?.config?.width || ""} x ${message.data?.config?.height || ""}<br>
            <strong>FPS:</strong> ${message.data?.fps || ""}<br>
            <strong>Bitrate:</strong> ${!message.data?.bitrate ? "" : (+message.data.bitrate / 8 / 1024).toFixed(0)} KB/s<br>
            <strong>RX Frames:</strong> ${message.data?.rx_frames || ""}<br>
            <strong>RX Bytes:</strong> ${!message.data?.rx_bytes ? "" : (+message.data.rx_bytes / 1024 / 1024).toFixed(2)}MB<br>
            <strong>Video Time:</strong> ${message.data?.absolute_dts || ""}<br>
            <strong>Frame To Decode:</strong> ${message.data?.frame_to_decode || ""}<br>
            <strong>Status:</strong> ${message.data?.status || ""}
          `;

          store.commit('SET_WEBRTC_VIDEO_DETAILS_TO_HTML', this.videoDetailsToHtml);
        }, 100);

        Vue.videoworker.addEventListener("message", (message) => {
          updateVideoDetails(message);

          if (message?.data?.status === 'config') {
            if (!router.currentRoute?.params?.deviceId) return
            if (this.configured) return
            store.commit('ADD_INFO_TO_SITE_CAMERA_STREAM_CAPABILITIES', {config: message?.data?.config, deviceId: router.currentRoute?.params?.deviceId})
            this.configured = true
          }
        });
      },
      initAudioWorker: function() {
        Vue.audioworker.postMessage({
          type: 'init', 
          data: {
            encoding: '16bitInt',
            sampleRate: 8000,
            channels: this.channels,
            kernelBufferSize: this.kernelBufferSize
          }
        })
  
        // listen PCM audio date from audio-worker.js
        Vue.audioworker.onmessage = function(event) {
          // Pass audio data to audio worklet processor
          if (event.data.type === 'flush' && this.audioWorkletNode) this.audioWorkletNode.port.postMessage(event?.data?.data?.samples)
        }.bind(this);
      },
      addAudioContext: async function () {
        await Vue.tool.prepareUnmuteIosAudio()
  
        this.audioContext = await Vue.tool.createAudioContext()
  
        this.gainNode = this.audioContext.createGain();
        this.gainNode.gain.value = 1;
  
        if (this.audioContext) await this.audioContext.audioWorklet.addModule('/js/audio-processor.js');
  
        this.audioWorkletNode = new AudioWorkletNode(this.audioContext, 'audio-processor', {outputChannelCount: [1]});
  
        this.audioWorkletNode.connect(this.gainNode).connect(this.audioContext.destination)
        
        store.commit('STORE_WEBRTC_AUDIO_CONTEXT', this.audioContext)
  
        this.audioContext.onstatechange = async (e) => {
          // store audio state in vuex
          store.commit('UPDATE_WEBRTC_AUDIO_STATE', e.target.state)
        };
  
        // default is pause
        this.pauseAudio()
      },
      addAudioContextSender: async function () {
        this.audioContextSender = await Vue.tool.createAudioContext()
        if (this.audioContextSender) await this.audioContextSender.audioWorklet.addModule('/js/audio-processor-sender.js');
  
        this.audioWorkletNodeSender = new AudioWorkletNode(this.audioContextSender, 'audio-processor-sender', {
          inputChannelCount: [1],
        });   
  
        this.audioContextSender.suspend()
  
        store.commit('STORE_WEBRTC_AUDIO_SENDER_CONTEXT', this.audioContextSender)
        store.commit('UPDATE_WEBRTC_AUDIO_SENDER_STATE', this.audioContextSender.state)
  
        this.audioContextSender.onstatechange = (e) => {
          // store audio state in vuex
          store.commit('UPDATE_WEBRTC_AUDIO_SENDER_STATE', e.target.state)
        };
      },
      // prepare modules
      ////////////////////////////////////////////
  
      ////////////////////////////////////////////
      // devices
      createBridges: function (device) {
        let bridgeInfo = {
          isOnline: !!device.networkInfo?.isOnline,
          isFwSupportWebrtc: device.hardwareInfo?.firmwareVersionToPadding >= Vue.tool.toComparableFWVersion('4.4.0.12'),
          isFwSupportDeviceCapabilities: device.hardwareInfo?.firmwareVersionToPadding >= Vue.tool.toComparableFWVersion('4.4.0.33'),
          deviceId: device.deviceId,
          cameras: [],
          peers: {},
          peersIndex: 2, // 1: front channel, 2: back channel
          reconnectTry: 0,
        }
  
        this.bridges.push(bridgeInfo)
      },
      createCameras: function (device) {
        let cameraInfo = {
          channel: device.hardwareInfo?.bridgeChannel,
          deviceId: device.deviceId,
          isOnline: !!device.networkInfo?.isOnline,
          bridgeId: device.hardwareInfo?.bridgeId,
          apiType: device?.apiType,
          mountType: device?.statusInfo?.mountType,
        }
        this.cameras.push(cameraInfo)
      },
      clearBridgePeer: async function (bridge, type) {
        if (!bridge || !type) return
        if (type === 'front') await bridge.peers?.['front']?.close()
        if (type === 'back') await bridge.peers?.['back']?.close()
        delete bridge.peers?.[type]
      },
      clearBridgePeers: async function (bridge) {
        await bridge.peers?.['front']?.close()
        await bridge.peers?.['back']?.close()
        bridge.peers = {}
      },
      getRequestBridgeAndCamera: function (cameraId) {
        try {
          if (!cameraId) return {}
          for (let i = 0; i < this.bridges.length; i++) {
            const bridge = this.bridges[i];
  
            if (!bridge?.cameras?.length) continue
  
            for (let ii = 0; ii < bridge.cameras.length; ii++) {
              const camera = bridge.cameras[ii];
              if (camera.deviceId === cameraId) {
                return {bridge, camera}
              }
            }
          }

          return {}
        }
        catch (err) {
          console.log(err);
        }
      },
      getBridgeListFromDeviceList: function () {
        if (!(store.getters?.siteDevices instanceof Object)) return

        this.bridges = []
        this.cameras = []
  
        Object.values(store.getters.siteDevices).forEach(device => {
          if (device.hardwareInfo.deviceType === 11) this.createBridges(device)
          else this.createCameras(device)
        })
  
        for (let i = 0; i < this.bridges.length; i++) {
          const bridge = this.bridges[i];
  
          for (let ii = 0; ii < this.cameras.length; ii++) {
            const camera = this.cameras[ii];
            if (bridge.deviceId == camera.bridgeId) {
              bridge.cameras.push(camera)
            }
          }
        }
  
        Vue.tool.initWebrtcBridges(this.bridges)
      },
      // devices
      ////////////////////////////////////////////
  
      ////////////////////////////////////////////
      // control video
      playStream: async function(cameraId) {
        if (!cameraId) return
  
        try {
          const {bridge, camera} = this.getRequestBridgeAndCamera(cameraId)
  
          if (!bridge || !camera) return
          if (!bridge?.isOnline) return
          if (!camera?.isOnline) return
          if (!bridge?.peers?.['front']?.is_connected) return
  
          // Get camera capabilities
          let capabilities = await this.getCapabilities(bridge, camera)

          let streamUrl = capabilities?.url
          let hasAudio = capabilities?.audio?.has
          let hasVideo = capabilities?.video?.has
          let audioCodec = capabilities?.audio?.codec
          
          // Request packet to bridge
          this.sendPlayRequestToBridge(bridge, cameraId, streamUrl, hasAudio, hasVideo, audioCodec)
        }
        catch (err) {
          console.log(err);
        }
      },
      stopStream: function (cameraId) {
        return new Promise((resolve, reject) => {
          if (!cameraId) return
  
          try {
            const {bridge} = this.getRequestBridgeAndCamera(cameraId)
    
            if (!bridge) return
            if (!bridge?.isOnline) return

            // cammand data callback
            bridge.peers?.['front']?.set_on_command_data_callback((e) => {
              if (e?.response_type === 'stream_stop') {
                resolve()
              }
            })
        
            // Request packet to bridge
            bridge.peers?.['front']?.stop_stream()
          }
          catch (err) {
            reject(err)
            console.log(err);
          }
        })
      },
      changeStream: async function (stopCameraId, playCameraId) {
        try {
          await this.stopStream(stopCameraId)
          this.setCanvas(playCameraId)
          this.playStream(playCameraId)
        } 
        catch (err) {
          console.log(err);
        }
      },
      setCanvas: function (playCameraId) {
        try {
          let videoViewer = document.getElementById(`--chekt-live-video-viewer-${playCameraId}`)

          if (!this.isSupportedVideoDecoder) return
          if (!videoViewer) return
          // 이유: transferControlToOffscreen는 한 canvas 요소에 대해서 한 번만 호출할 수 있음
          videoViewer.innerHTML = ""; // 기존 canvas 요소를 제거
          const newCanvas = document.createElement("canvas");
          newCanvas.style.width = "100%";
          newCanvas.style.height = "auto";
          newCanvas.id = `--chekt-live-video-canvas-${playCameraId}`;
  
          videoViewer.appendChild(newCanvas); // 새 canvas 요소를 추가
          const canvas = newCanvas.transferControlToOffscreen();
  
          Vue.videoworker.postMessage({ type: "setup", rendererName: "webgl", canvas }, [canvas]);
  
          if (store.getters.isFullscreen) newCanvas.style.maxHeight = document.body.clientHeight + "px";
          else newCanvas.style.maxHeight = ""; 

          return newCanvas
        } 
        catch (err) {
          console.log(err);
        }
      },
      sendPlayRequestToBridge: function (bridge, cameraId, streamUrl, hasAudio, hasVideo, audioCodec) {
        if (!bridge || !streamUrl) return
        if (store.getters.currentPageDeviceId !== cameraId) return this.playStream(store.getters.currentPageDeviceId)

        let isStopRequested = false
        this.lastRequestedCameraId = cameraId
        const hasVideoPermission = store.getters.site(store?.getters?.selectedSiteId)?.statusInfo?.showVideo
        const hasListenPermission = store.getters.site(store?.getters?.selectedSiteId)?.statusInfo?.showListen
        const hasAllPermission = hasVideoPermission && hasListenPermission

        if (bridge.isFwSupportDeviceCapabilities) {
          if (hasAllPermission && this.isSupportedVideoDecoder) {
            bridge.peers?.['front']?.set_stream(streamUrl, 'va')
          } else if (hasVideoPermission && this.isSupportedVideoDecoder) {
            bridge.peers?.['front']?.set_stream(streamUrl, 'video')
          } else if (hasListenPermission) {
            bridge.peers?.['front']?.set_stream(streamUrl, 'audio')
          }
        } else {
          if (this.isSupportedVideoDecoder) bridge.peers?.['front']?.set_stream(streamUrl, 'va')
        }

        bridge.peers?.['front']?.play_stream()
        this.configured = false
  
        // video data callback
        if (hasVideo) {
          bridge.peers?.['front']?.set_on_video_data_callback((e) => {
            if (this.streamStatus === 'destroyed') {
              if (!isStopRequested) this.stopStream(this.lastRequestedCameraId)
              isStopRequested = true
              return
            }

            Vue.videoworker.postMessage({ type: "video_data", video_data: e.data });
          })
        }
  
        // audio data callback
        if (hasAudio && store.getters.siteMemberMePermission?.listenAudio) {
          bridge.peers?.['front']?.set_on_audio_data_callback((e) => {
            Vue.audioworker.postMessage({type: 'packet', data: new Uint8Array(e?.data), codec: audioCodec})
          })
        }
      },
      // control video
      ////////////////////////////////////////////
  
      ////////////////////////////////////////////
      // control auido
      pauseAudio: function() {
        if (this.audioContext.state === 'running') this.audioContext.suspend()
      },
      resumeAudio: function() {
        if (this.audioContext.state === 'suspended') this.audioContext.resume()
        else if (this.audioContext.state === 'interrupted') {
          this.audioContext.suspend()
          this.audioContext.resume()
        }
      },
      resetAudioPacket: function () {
        if (Vue.audioworker) Vue.audioworker.postMessage({ type: 'reset'})
        if (this.audioWorkletNode) this.audioWorkletNode.port.postMessage('reset')
      },
      // control auido
      ////////////////////////////////////////////
  
      ////////////////////////////////////////////
      // control auido sender
      playAudioSender: async function (cameraId) {
        try {
          if (!this.senderStream) this.senderStream = await navigator.mediaDevices.getUserMedia({ 
            audio: { 
              sampleRate: 8000,
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true,
            }
          });
          this.senderSource = await this.audioContextSender.createMediaStreamSource(this.senderStream);
          await this.senderSource.connect(this.audioWorkletNodeSender).connect(this.audioContextSender.destination)
  
          this.isAudioSenderConnected= true
  
          this.playAudioSenderStream(cameraId)
        }
        catch (err) {
          if (err.name === "NotAllowedError") {
            if (confirm("Microphone access is required. Would you like to go to settings to allow it?")) Vue.tool.openNativeAppSettings()
          }
          else console.log(err);
        }
      },
      stopAudioSender: async function (cameraId) {
        try {
          if (!this.isAudioSenderConnected) return
  
          this.senderSource.disconnect(this.audioWorkletNodeSender)
          this.audioWorkletNodeSender.disconnect(this.audioContextSender.destination)
          await this.audioContextSender.suspend()
  
          this.isAudioSenderConnected = false
          this.stopAudioSenderStream(cameraId)
          await this.removeAudioSender()
        } 
        catch (err) {
          console.log(err);
        }
      },
      playAudioSenderStream: async function(cameraId) {
        if (!cameraId) return
        try {
          const {bridge, camera} = this.getRequestBridgeAndCamera(cameraId)
  
          let capabilities = await this.getCapabilities(bridge, camera)

          this.sendPushRequestToBridge(bridge, capabilities?.audioBc?.url)
          this.audioContextSender.resume()
        }
        catch (err) {
          console.log(err);
        }
      },
      stopAudioSenderStream: function (cameraId) {
        const {bridge} = this.getRequestBridgeAndCamera(cameraId)
        if (bridge) bridge?.peers?.['back']?.stop_stream()
      },
      initPlayback: async function (cameraId, stime, request_uri) {
        const {bridge} = this.getRequestBridgeAndCamera(cameraId)
        if (!bridge) return

        // let recodringConfig = store.getters?.cameraOnvifRecordingConfig(cameraId)
        // if (!recodringConfig) return

        await this.stopStream(cameraId)
        this.setCanvas(cameraId)

        if (this.isSupportedVideoDecoder) bridge.peers?.['front']?.set_stream(request_uri, 'playback')
        else return alert(`Can't play playback video. This device does not support video decode feature.`)

        bridge?.peers?.['front']?.play_stream(stime)
      },
      playPlayback: async function (cameraId, stime) {
        const {bridge} = this.getRequestBridgeAndCamera(cameraId)

        if (!bridge) return

        bridge?.peers?.['front']?.play_playback(stime)
      },
      pausePlayback: function (cameraId) {
        const {bridge} = this.getRequestBridgeAndCamera(cameraId)
        if (bridge) bridge?.peers?.['front']?.pause_playback()
      },
      getClient: function (cameraId) {
        const {bridge} = this.getRequestBridgeAndCamera(cameraId)
        bridge?.peers?.['front']?.get_client()
      },
      sendPushRequestToBridge: async function (bridge, streamUrl) {
        bridge?.peers?.['back']?.send_command(`{
          "request_type": "stream_setup",
          "request": { "stream_url": "${streamUrl}", "media":"audio_bc"}
        }`)
  
        this.audioWorkletNodeSender.port.onmessage = (event) => {
          try {
            const int16Array = event?.data?.data;
            bridge?.peers?.['back']?.send_audio_bc(int16Array?.buffer)
          }
          catch (err) {
            console.log(err);
          }
        }
      },
      removeAudioSender: async function () {
        try {
          await this.stopAudioSender()
          if (this.senderStream) {
            this.senderStream.getTracks().forEach(track => {
              track.stop();
            });
            this.senderStream = null;
          }
        } 
        catch (err) {
          console.log(err);
        }
      },
      movePtz: function ({cameraId, channel, profileToken, p, t, z, brand}) {
        if (channel < 10) channel = '0' + channel 

        const {bridge, camera} = this.getRequestBridgeAndCamera(cameraId)
        if (!bridge || !camera) return

        let bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
        if (Vue.tool.isNumber(p)) bridgeApiPathQueryOptions += `&p=${p}`;
        if (Vue.tool.isNumber(t)) bridgeApiPathQueryOptions += `&t=${t}`;
        if (Vue.tool.isNumber(z)) bridgeApiPathQueryOptions += `&z=${z}`;
    
        const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);
        
        let uri = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZContinuousMoveQuery(brand)}&options=${encodedUri}`
        bridge?.peers?.['front']?.request_proxy(uri)
      },
      stopPtz: function ({cameraId, channel, profileToken, brand}) {
        if (channel < 10) channel = '0' + channel 

        const {bridge, camera} = this.getRequestBridgeAndCamera(cameraId)
        if (!bridge || !camera) return

        let bridgeApiPathQueryOptions = `profile_token=${profileToken}`;
        const encodedUri = encodeURIComponent(bridgeApiPathQueryOptions);
        
        let uri = `ckbapiv2/camera/ch${channel}/onvif/request?request=${helper.getPTZStopQuery(brand)}&options=${encodedUri}`
        bridge?.peers?.['front']?.request_proxy(uri)
      },
      checkVideoDecoderSupport: async function () {
        if (!('VideoDecoder' in self)) return false

        const codecs = [
          // H.264
          "avc1.42001E", 
          "avc1.42E01E", 
          "avc1.4D401F", 
          "avc1.4D001F", 
          "avc1.64001F", 
          "avc1.640028",
          // Others...
          // "vp8", 
          // "vp09.00.10.08",
          // "av01.0.04M.08", 
        ];
        const accelerations = ["prefer-software", "prefer-hardware"];
        const configs = [];

        for (const codec of codecs) {
          for (const acceleration of accelerations) {
            configs.push({
              codec,
              hardwareAcceleration: acceleration,
              codedWidth: 1280,
              codedHeight: 720,
            });
          }
        }

        for (const config of configs) {
          try {
            const support = await VideoDecoder.isConfigSupported(config);

            // console.log(
            //   `VideoDecoder's config ${JSON.stringify(support.config)} support: ${
            //     support.supported
            //   }`,
            // );

            // H.264 지원 여부
            if (support?.supported) return true
          } 
          catch (error) {
            console.error(`Error checking config ${JSON.stringify(config)}:`, error);
            return false
          }
        }

        return false; 
      }
      // control auido sender
      ////////////////////////////////////////////
    }
    Vue.prototype.$webrtc = Vue.observable(webrtc);
    Vue.webrtc = Vue.observable(webrtc);
  }
}

export default WebrtcPlugin