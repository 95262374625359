const baseRoutes = [
  {
    path: '/settings',
    component: () => import("@/_routes/settings/Root"),
  },
  {
    path: '/settings/cameras',
    component: () => import("@/_routes/settings/Cameras")
  },
  {
    path: '/settings/site',
    component: () => import("@/_routes/settings/Site")
  },
  {
    path: '/settings/help',
    component: () => import("@/_routes/settings/Help")
  },
  {
    path: '/settings/debug',
    component: () => import("@/_routes/settings/Debug")
  },
  {
    path: '/settings/armingschedule',
    component: () => import("@/_routes/settings/ArmingSchedule")
  },
  {
    path: '/settings/bridgesstatus',
    component: () => import("@/_routes/settings/BridgesStatus")
  },   
  {
    path: '/settings/appearance',
    component: () => import("@/_routes/settings/Appearance")
  },   
  {
    path: '/settings/incidentreports',
    component: () => import("@/_routes/settings/incidentReports/IncidentReports")
  },
]

import accountRoutes              from '@/_routes/settings/account/_router'
import membersRoutes              from '@/_routes/settings/members/_router'
import armingRoutes               from '@/_routes/settings/arming/_router'
import notificationsRoutes        from '@/_routes/settings/notifications/_router'
import agreementRoutes            from '@/_routes/settings/agreement/_router'
import incidentReportsRoutes      from '@/_routes/settings/incidentReports/_router'
const routes = baseRoutes.concat(accountRoutes)
                         .concat(membersRoutes)
                         .concat(armingRoutes)
                         .concat(notificationsRoutes)
                         .concat(agreementRoutes)
                         .concat(incidentReportsRoutes)

export default routes
