import Vue from 'vue'
import api from '@/apis/siteProxy'
import parser from '@/models/siteCameraOnvifProfiles'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,

  // GETs
  GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_REQUEST,
  GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_SUCCESS,
  GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_FAILURE,

  GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_REQUEST,
  GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_SUCCESS,
  GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_FAILURE,

  GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_FAILURE,

  GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_FAILURE,

  GET_ONVIF_TEMPLATE_FROM_PROXY_REQUEST,
  GET_ONVIF_TEMPLATE_FROM_PROXY_SUCCESS,
  GET_ONVIF_TEMPLATE_FROM_PROXY_FAILURE,

  GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_REQUEST,
  GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_SUCCESS,
  GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_FAILURE,

  // SETs
  SET_SITE_CAMERA_ONVIF_PROFILE_INDEX,

  // RESETs
  RESET_SITE_CAMERAS_CAPABILITIES_FROM_PROXY,
  RESET_SITE_ONVIFS_FROM_PROXY,

  // OTHER
  ADD_INFO_TO_SITE_CAMERA_STREAM_CAPABILITIES,
} from '@/store/mutation-types'


function isOnvifDataGivesSuccess(data) {
  let result = true
  
  if (data.data && 'Fault' in data.data) result = false
  if (Array.isArray(data?.errors) && data?.errors?.length) result = false
  if (!Array.isArray(data?.errors) && data?.errors) result = false
  if (data?.error) result = false;

  // 에러 알려주기.
  if (!result) console.warn('ℹ️ proxy api is succeeded but, onvif gives errors in bypassed data')

  return result
}

let initialState = {
  camerasCapabilities: {},
  camerasStreamCapabilities: {},
  camerasOnvifProfiles: {},
  camerasOnvifSelectedProfileIndex: {},
  selectedCameraStream: {},
  onvifsRequestList: {},
  onvifsCapabilities: {},
  status: {
    cameraStreamCapabilities: null,
    cameraCapabilities: null,
    onvifCapabilities: null,
    onvifRequestList: null,
    getOnvifTemplate: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  camerasCapabilities: function (state) {
    return state.camerasCapabilities
  },
  cameraCapabilities: function (state, getters) {
    return function (deviceId) {
      return getters.camerasCapabilities[deviceId]
    }
  },
  camerasOnvifProfiles: function (state) {
    return state.camerasOnvifProfiles
  },
  cameraOnvifProfiles: function (state, getters) {
    return function (deviceId) {
      return getters.camerasOnvifProfiles[deviceId]
    }
  },
  camerasOnvifSelectedProfileIndex: function (state) {
    return state.camerasOnvifSelectedProfileIndex
  },
  cameraOnvifSelectedProfileIndex: function (state, getters) {
    return function (deviceId) {
      return getters.camerasOnvifSelectedProfileIndex[deviceId]
    }
  },
  camerasStreamCapabilities: function (state) {
    return state.camerasStreamCapabilities
  },
  cameraStreamCapabilities: function (state, getters) {
    return function (deviceId) {
      return getters.camerasStreamCapabilities[deviceId]
    }
  },
  statusCameraStreamCapabilities: function (state, getters) {
    return getters.status.cameraStreamCapabilities
  },
  onvifsCapabilities: function (state) {
    return state.onvifsCapabilities
  },
  onvifCapabilities: function (state, getters) {
    return function (deviceId) {
      return getters.onvifsCapabilities[deviceId]
    }
  },
  statusOnvifCapabilities: function (state, getters) {
    return getters.status.onvifCapabilities
  },
}

// actions
const actions = {
  getSiteCameraCapabilitiesFromProxy: function ({commit}, {siteId, bridgeId, channel, deviceId}) {
    commit(GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getCameraCapabilitiesFromProxy({siteId, bridgeId, channel}).then(
        res => {
          let data = res.body
          commit(GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_SUCCESS, {data, deviceId})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSiteCameraStreamCapabilitiesFromProxy: function ({state, commit}, {siteId, bridgeId, channel, deviceId}) {
    if (state.camerasStreamCapabilities?.[deviceId]) return

    commit(GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getCameraStreamCapabilitiesFromProxy({siteId, bridgeId, channel}).then(
        res => {
          let data = res.body
          commit(GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_SUCCESS, {data, deviceId})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },

  // PROFILE
  getSiteCameraOnvifProfilesFromProxy: function ({state, commit, rootState}, {siteId, bridgeId, channel, deviceId}) {
    if (state.camerasOnvifProfiles?.[deviceId]) return
    if (state.camerasStreamCapabilities?.[deviceId]) return

    commit(GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getCameraOnvifProfilesFromProxy({siteId, bridgeId, channel}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) {
            api.getCameraStreamCapabilitiesFromProxy({siteId, bridgeId, channel}).then(
              res => {
                let data = res.body
                commit(GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_SUCCESS, {data, deviceId})
                resolve()
                commit(PROCESS_API_SUCCESS)
              },
              err => {
                commit(GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_FAILURE)
                reject({
                  status: err.status,
                  statusText: err.body,
                })
                commit(PROCESS_API_FAILURE, {
                  status: err.status,
                  statusText: err.body,
                  origin: window.location.origin,
                  err: Vue.tool.parseToStringify(err),
                })
              }
            )
          }
          else {
            commit(GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_SUCCESS, {rootState, data, deviceId})
            resolve()
            commit(PROCESS_API_SUCCESS)
          }
        },
        err => {
          commit(GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },

  // LIST REQUEST
  getSiteRequestListFromProxy: function ({commit}, {siteId, bridgeId, deviceId}) {
    commit(GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifRequestListFromProxy({siteId, bridgeId}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_FAILURE)
          else commit(GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_SUCCESS, {data, deviceId})
        
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },

  // CAPABILITIES
  getSiteOnvifCapabilitiesFromProxy: function ({commit, state}, {siteId, bridgeId, channel, deviceId}) {
    if (state.onvifsCapabilities?.[deviceId]) return

    commit(GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifCapabilitiesFromProxy({siteId, bridgeId, channel}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_FAILURE)
          else commit(GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_SUCCESS, {data, deviceId})
          
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getOnvifTemplateFromProxy: function ({commit}, {siteId, bridgeId, templateName}) {
    commit(GET_ONVIF_TEMPLATE_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifTemplateFromProxy({siteId, bridgeId, templateName}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(GET_ONVIF_TEMPLATE_FROM_PROXY_FAILURE)
          else commit(GET_ONVIF_TEMPLATE_FROM_PROXY_SUCCESS, {data})

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_ONVIF_TEMPLATE_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_REQUEST]: function (state) {
    state.status.cameraStreamCapabilities = 'requested'
  },
  [GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    if (!data) return
    if (!(data instanceof Object)) return
    if (!deviceId) return
    if (!('url' in data)) return

    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasStreamCapabilities[deviceId]) {
      Vue.set(state.camerasStreamCapabilities, deviceId, {});
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasStreamCapabilities, deviceId, data);

    state.status.cameraStreamCapabilities = 'successful'
  },
  [GET_SITE_CAMERA_STREAM_CAPABILITIES_FROM_PROXY_FAILURE]: function (state) {
    state.status.cameraStreamCapabilities = 'failed'
  },

  [GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_REQUEST]: function (state) {
    state.status.cameraOnvifProfiles = 'requested'
  },
  [GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_SUCCESS]: function (state, {rootState, data, deviceId}) {
    if (!data) return
    if (!(data instanceof Object)) return
    if (!(data.data instanceof Array)) return

    let videoProfileToken = rootState.device?.devices?.[deviceId]?.videoProfileToken
    let parsedProfiles = []
    let defaultProfileIndex = 1

    const legitProfiles = data.data.map(v => parser.parseSiteCammeraOnvifProfiles(v)).filter(p => p !== undefined);
    
    legitProfiles.forEach((parsedProfile, i) => {
      if (videoProfileToken === parsedProfile?.profileToken) {
        defaultProfileIndex = i
      }
      if (parsedProfile) parsedProfiles.push(parsedProfile)
    });

    parsedProfiles.forEach((profile, i) => {
      profile.video.index = i
    })

    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasOnvifProfiles[deviceId]) {
      Vue.set(state.camerasOnvifProfiles, deviceId, {});
    }

    if (!state.camerasOnvifSelectedProfileIndex[deviceId]) {
      Vue.set(state.camerasOnvifSelectedProfileIndex, deviceId, null);
    }

    if (parsedProfiles.length > 1) state.camerasOnvifSelectedProfileIndex[deviceId] = defaultProfileIndex
    else state.camerasOnvifSelectedProfileIndex[deviceId] = 0

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasOnvifProfiles, deviceId, parsedProfiles);

    state.status.cameraOnvifProfiles = 'successful'
  },
  [GET_SITE_CAMERA_ONVIF_PROFILES_FROM_PROXY_FAILURE]: function (state) {
    state.status.cameraOnvifProfiles = 'failed'
  },
  
  [GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_REQUEST]: function (state) {
    state.status.cameraCapabilities = 'requested'
  },
  [GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasCapabilities[deviceId]) {
      Vue.set(state.camerasCapabilities, deviceId, {});
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasCapabilities, deviceId, data);

    state.status.cameraCapabilities = 'successful'
  },
  [GET_SITE_CAMERA_CAPABILITIES_FROM_PROXY_FAILURE]: function (state) {
    state.status.cameraCapabilities = 'failed'
  },

  [GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_REQUEST]: function (state) {
    state.status.onvifRequestList = 'requested'
  },
  [GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    if (!data) return
    if (!(data instanceof Object)) return

    let onvifRequestList = []
    for(const key in data) {
      const list = data[key]

      list.forEach((item) => {
        if(key === 'base') {
          onvifRequestList.push(item)
        } else {
          onvifRequestList.push(`${key}/${item}`)
        }
      })
    }

    // 없는 경우 빈 객체를 먼저 설정
    if (!state.onvifsRequestList[deviceId]) {
      Vue.set(state.onvifsRequestList, deviceId, {});
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.onvifsRequestList, deviceId, data.data);
    state.status.onvifRequestList = 'successful'
  },
  [GET_SITE_ONVIF_REQUEST_LIST_FROM_PROXY_FAILURE]: function (state) {
    state.status.onvifRequestList = 'failed'
  },
  [GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_REQUEST]: function (state) {
    state.status.onvifCapabilities = 'requested'
  },
  [GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    if (!deviceId) return
    if (!data.data) return
    if (!(data.data instanceof Object)) return
    if (!('audio_outputs' in data.data)) return


    // 없는 경우 빈 객체를 먼저 설정
    if (!state.onvifsCapabilities[deviceId]) {
      Vue.set(state.onvifsCapabilities, deviceId, {});
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.onvifsCapabilities, deviceId, data.data);

    state.status.onvifCapabilities = 'successful'
  },
  [GET_SITE_ONVIF_CAPABILITIES_FROM_PROXY_FAILURE]: function (state) {
    state.status.onvifCapabilities = 'failed'
  },

  [GET_ONVIF_TEMPLATE_FROM_PROXY_REQUEST]: function (state) {
    state.status.getOnvifTemplate = 'requested'
  },
  [GET_ONVIF_TEMPLATE_FROM_PROXY_SUCCESS]: function (state) {
    state.status.getOnvifTemplate = 'successful'
  },
  [GET_ONVIF_TEMPLATE_FROM_PROXY_FAILURE]: function (state) {
    state.status.getOnvifTemplate = 'failed'
  },

  [SET_SITE_CAMERA_ONVIF_PROFILE_INDEX]: function (state, {deviceId, index}) {
    if (!state.camerasOnvifSelectedProfileIndex[deviceId]) {
      Vue.set(state.camerasOnvifSelectedProfileIndex, deviceId, null);
    }

    state.camerasOnvifSelectedProfileIndex[deviceId] = index
  },

  // RESETs
  [RESET_SITE_CAMERAS_CAPABILITIES_FROM_PROXY]: function (state) {
    state.camerasCapabilities = {}
    state.camerasStreamCapabilities = {}
  },
  [RESET_SITE_ONVIFS_FROM_PROXY]: function (state) {
    state.onvifsCapabilities =  {}
    state.camerasOnvifSelectedProfileIndex = {}
    state.camerasOnvifProfiles = {}
  },

  // OTHER
  [ADD_INFO_TO_SITE_CAMERA_STREAM_CAPABILITIES]: function (state, {config, deviceId}) {
    if (!config) return
    if (!deviceId) return

    // 없는 경우 무시
    if (!state.camerasStreamCapabilities?.[deviceId]?.video) return

    state.camerasStreamCapabilities[deviceId].video.resolution = `${config?.width}x${config?.height}`

    Vue.set(state.camerasStreamCapabilities, deviceId, {...state.camerasStreamCapabilities[deviceId]});
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}