import Vue from 'vue'
import api from '@/apis/siteProxyPtz'
import parser from '@/models/siteCameraOnvifPtz'
import helper from '@/models/siteCameraTool'

import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,

  // GETs
  GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_FAILURE,

  GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_FAILURE,

  GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_FAILURE,

  // SETs
  SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_FAILURE,

  SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_FAILURE,

  SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_FAILURE,

  SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_FAILURE,

  SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_FAILURE,

  SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_FAILURE,

  SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_FAILURE,

  SET_CAMERA_PTZ_AREA_ZOOM_REQUEST,
  SET_CAMERA_PTZ_AREA_ZOOM_SUCCESS,
  SET_CAMERA_PTZ_AREA_ZOOM_FAILURE,
} from '@/store/mutation-types'


function isOnvifDataGivesSuccess(data) {
  let result = true
  
  if (data.data && 'Fault' in data.data) result = false
  if (Array.isArray(data?.errors) && data?.errors?.length) result = false
  if (!Array.isArray(data?.errors) && data?.errors) result = false
  if (data?.error) result = false;

  // 에러 알려주기.
  if (!result) console.warn('ℹ️ proxy api is succeeded but, onvif gives errors in bypassed data')

  return result
}

let initialState = {
  camerasPtzRange: {},
  camerasPtzCurrentPosition: {},
  camerasOnvifPtzPresets: {},
  status: {
    isMovingPTZ: null,
    camerasPtzRange: null,
    setSiteOnvifRelativePtzFromProxy: null,
    setSiteContinuousPtzFromProxy: null,
    setSiteStopPtzFromProxy: null,
    setSiteOnvifAbsolutePtzFromProxy: null,
    setCameraOnvifRelativePtzFromProxy: null,
    getCameraOnvifPtzStatus: null,
    setCameraOnvifPtzGoToHome: null,
    setCameraOnvifPtzSetToHome: null,
    getCamerasOnvifPtzPresets: null,
    setCameraPtzAreaZoomFromProxy: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  isMovingPTZ: function (state) {
    return state.status.isMovingPTZ
  },
  camerasPtzRange: function (state) {
    return state.camerasPtzRange
  },
  cameraPtzRange: function (state, getters) {
    return function (deviceId) {
      return getters.camerasPtzRange[deviceId]
    }
  },
  camerasPtzCurrentPosition: function (state) {
    return state.camerasPtzCurrentPosition
  },
  cameraPtzCurrentPosition: function (state, getters) {
    return function (deviceId) {
      return getters.camerasPtzCurrentPosition[deviceId]
    }
  },
  camerasOnvifPtzPresets: function (state) {
    return state.camerasOnvifPtzPresets
  },
  cameraOnvifPtzPresets: function (state, getters) {
    return function (deviceId) {
      return getters.camerasOnvifPtzPresets[deviceId] || []
    }
  },
  statusSetSiteOnvifRelativePtzFromProxy: function (state) {
    return state.status.setSiteOnvifRelativePtzFromProxy
  },
  statusSetSiteContinuousPtzFromProxy: function (state) {
    return state.status.setSiteContinuousPtzFromProxy
  },
  statusSetSiteStopPtzFromProxy: function (state) {
    return state.status.setSiteStopPtzFromProxy
  },
  statusSetSiteOnvifAbsolutePtzFromProxy: function (state) {
    return state.status.setSiteOnvifAbsolutePtzFromProxy
  },
  statusGetCameraOnvifPtzStatus: function (state) {
    return state.status.getCameraOnvifPtzStatus
  },
  statusSetCameraPtzAreaZoomFromProxy: function (state) {
    return state.status.setCameraPtzAreaZoomFromProxy
  }
}

// actions
const actions = {
  // PTZ Range
  getSitePtzRangeFromProxy: function ({commit}, {siteId, bridgeId, channel, deviceId, brand}) {
    if (state.camerasPtzRange?.[deviceId]) return

    commit(GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifPtzConfigurationsFromProxy({siteId, bridgeId, channel, brand}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_FAILURE)
          else commit(GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_SUCCESS, {data, deviceId, brand})

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },

  // PTZ Current position
  getSitePtzCurrentPositionFromProxy: function ({commit}, {siteId, bridgeId, channel, deviceId, profileToken, brand='ONVIF'}) {
    commit(GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifPtzStatusFromProxy({siteId, bridgeId, channel, profileToken, brand}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_FAILURE)
          else commit(GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_SUCCESS, {data, deviceId, brand})

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },

  // SETs
  setSiteOnvifRelativePtzFromProxy: function ({commit}, {siteId, bridgeId, channel, profileToken,  p, t, z}) {
    commit(SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.setOnvifRelativePtzFromProxy({siteId, bridgeId, channel, profileToken, p, t, z}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_FAILURE)
          else commit(SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_SUCCESS)

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },

  setSiteContinuousPtzFromProxy: function ({commit}, {siteId, bridgeId, channel, profileToken, p, t, z, brand='ONVIF'}) {
    commit(SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.setOnvifContinuousPtzFromProxy({siteId, bridgeId, channel, profileToken, p, t, z, brand}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_FAILURE)
          else commit(SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_SUCCESS)

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  setSiteStopPtzFromProxy: function ({commit}, {siteId, bridgeId, channel, profileToken, brand='ONVIF'}) {
    commit(SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.setOnvifStopPtzFromProxy({siteId, bridgeId, channel, profileToken, brand}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_FAILURE)
          else commit(SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_SUCCESS)

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  setSiteOnvifAbsolutePtzFromProxy: function ({commit}, {siteId, bridgeId, channel, profileToken,  p, t, z}) {
    commit(SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.setOnvifAbsolutePtzFromProxy({siteId, bridgeId, channel, profileToken, p, t, z}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_FAILURE)
          else commit(SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_SUCCESS)

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },

  // Go to home
  setSitePtzGoToHomeFromProxy: function ({commit}, {siteId, bridgeId, channel, profileToken, brand='ONVIF'}) {
    commit(SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.setOnvifPtzGoToHomeFromProxy({siteId, bridgeId, channel, profileToken, brand}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_FAILURE)
          else commit(SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_SUCCESS)

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  setSitePtzSetToHomeFromProxy: function ({commit}, {siteId, bridgeId, channel, profileToken, brand='ONVIF'}) {
    commit(SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.setOnvifPtzSetToHomeFromProxy({siteId, bridgeId, channel, profileToken, brand}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_FAILURE)
          else commit(SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_SUCCESS)

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSiteOnvifPtzPresetsFromProxy: function ({state, commit}, {siteId, bridgeId, deviceId, channel, profileToken, brand='ONVIF'}) {
    if (state.camerasOnvifPtzPresets?.[deviceId]) return

    commit(GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifPtzPresetsFromProxy({siteId, bridgeId, channel, profileToken, brand}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_FAILURE)
          else commit(GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_SUCCESS, {data, deviceId, brand})

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  setSiteOnvifPtzGoToPresetFromProxy: function ({commit}, {siteId, bridgeId, channel, profileToken, presetToken, brand='ONVIF'}) {
    commit(SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.setOnvifPtzGoToPresetFromProxy({siteId, bridgeId, channel, profileToken, presetToken, brand}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_FAILURE)
          else commit(SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_SUCCESS, {data})

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  setCameraPtzAreaZoomFromProxy: function ({commit}, {siteId, bridgeId, channel, p, t, z, brand}) {
    commit(SET_CAMERA_PTZ_AREA_ZOOM_REQUEST)
    return new Promise((resolve, reject) => {
      api.setCameraPtzAreaZoomFromProxy({siteId, bridgeId, channel, p, t, z, brand}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(SET_CAMERA_PTZ_AREA_ZOOM_FAILURE)
          else commit(SET_CAMERA_PTZ_AREA_ZOOM_SUCCESS)

          resolve()
        },
        err => {
          commit(SET_CAMERA_PTZ_AREA_ZOOM_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
        }
      )
    })
  }
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_REQUEST]: function (state) {
    state.status.camerasPtzRange = 'requested'
  },
  [GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_SUCCESS]: function (state, {data, deviceId, brand}) {
    const parsedPtz = helper.getPTZConfigurationParser({brand, data});

    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasPtzRange[deviceId]) {
      Vue.set(state.camerasPtzRange, deviceId, {});
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasPtzRange, deviceId, parsedPtz);

    state.status.camerasPtzRange = 'successful'
  },
  [GET_SITE_ONVIF_PTZ_CONFIGS_FROM_PROXY_FAILURE]: function (state) {
    state.status.camerasPtzRange = 'failed'
  },
  [GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_REQUEST]: function (state) {
    state.status.getCamerasOnvifPtzPresets = 'requested'
  },
  [GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_SUCCESS]: function (state, {data, deviceId, brand}) {
    const ptzPresets = helper.getPTZPresetsParser(brand, data)

    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasOnvifPtzPresets[deviceId]) {
      Vue.set(state.camerasOnvifPtzPresets, deviceId, []);
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasOnvifPtzPresets, deviceId, ptzPresets);
    state.status.getCamerasOnvifPtzPresets = 'successful'
  },
  [GET_SITE_ONVIF_PTZ_PRESETS_FROM_PROXY_FAILURE]: function (state) {
    state.status.getCamerasOnvifPtzPresets = 'failed'
  },
  [SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_REQUEST]: function (state) {
    state.status.setSiteOnvifRelativePtzFromProxy = 'requested'
  },
  [SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_SUCCESS]: function (state) {
    state.status.setSiteOnvifRelativePtzFromProxy = 'successful'
    state.status.isMovingPTZ = false
  },
  [SET_SITE_ONVIF_RELATIVE_PTZ_FROM_PROXY_FAILURE]: function (state) {
    state.status.setSiteOnvifRelativePtzFromProxy = 'failed'
    state.status.isMovingPTZ = false
  },
  [SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_REQUEST]: function (state) {
    state.status.setSiteContinuousPtzFromProxy = 'requested'
    state.status.isMovingPTZ = true
  },
  [SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_SUCCESS]: function (state) {
    state.status.setSiteContinuousPtzFromProxy = 'successful'
  },
  [SET_SITE_ONVIF_CONTINUOUS_PTZ_FROM_PROXY_FAILURE]: function (state) {
    state.status.setSiteContinuousPtzFromProxy = 'failed'
    state.status.isMovingPTZ = false
  },
  [SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_REQUEST]: function (state) {
    state.status.isMovingPTZ = true
    state.status.setSiteStopPtzFromProxy = 'requested'
  },
  [SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_SUCCESS]: function (state) {
    state.status.isMovingPTZ = false
    state.status.setSiteStopPtzFromProxy = 'successful'
  },
  [SET_SITE_ONVIF_STOP_PTZ_FROM_PROXY_FAILURE]: function (state) {
    state.status.isMovingPTZ = false
    state.status.setSiteStopPtzFromProxy = 'failed'
  },
  [SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_REQUEST]: function (state) {
    state.status.setSiteOnvifAbsolutePtzFromProxy = 'requested'
  },
  [SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_SUCCESS]: function (state) {
    state.status.setSiteOnvifAbsolutePtzFromProxy = 'successful'
  },
  [SET_SITE_ONVIF_ABSOLUTE_PTZ_FROM_PROXY_FAILURE]: function (state) {
    state.status.setSiteOnvifAbsolutePtzFromProxy = 'failed'
  },
  [GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_REQUEST]: function (state) {
    state.status.getCameraOnvifPtzStatus = 'requested'
  },
  [GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_SUCCESS]: function (state, {data, deviceId, brand}) {
    const ptzStatus = helper.parsePTZStatus({brand, data})

    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasPtzCurrentPosition[deviceId]) {
      Vue.set(state.camerasPtzCurrentPosition, deviceId, {});
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasPtzCurrentPosition, deviceId, ptzStatus);
    state.status.getCameraOnvifPtzStatus = 'successful'
  },
  [GET_SITE_ONVIF_PTZ_STATUS_FROM_PROXY_FAILURE]: function (state) {
    state.status.getCameraOnvifPtzStatus = 'failed'
  },
  [SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_REQUEST]: function (state) {
    state.status.setCameraOnvifPtzGoToHome = 'requested'
  },
  [SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_SUCCESS]: function (state) {
    state.status.setCameraOnvifPtzGoToHome = 'successful'
  },
  [SET_SITE_ONVIF_PTZ_GO_TO_HOME_FROM_PROXY_FAILURE]: function (state) {
    state.status.setCameraOnvifPtzGoToHome = 'failed'
  },
  [SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_REQUEST]: function (state) {
    state.status.setCameraOnvifPtzSetToHome = 'requested'
  },
  [SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_SUCCESS]: function (state) {
    state.status.setCameraOnvifPtzSetToHome = 'successful'
  },
  [SET_SITE_ONVIF_PTZ_SET_TO_HOME_FROM_PROXY_FAILURE]: function (state) {
    state.status.setCameraOnvifPtzSetToHome = 'failed'
  },
  [SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_REQUEST]: function (state) {
    state.status.setCameraOnvifPtzSetToPreset = 'requested'
  },
  [SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_SUCCESS]: function (state) {
    state.status.setCameraOnvifPtzSetToPreset = 'successful'
  },
  [SET_SITE_ONVIF_PTZ_GO_TO_PRESET_FROM_PROXY_FAILURE]: function (state) {
    state.status.setCameraOnvifPtzSetToPreset = 'failed'
  },
  [SET_CAMERA_PTZ_AREA_ZOOM_REQUEST]: function (state) {
    state.status.setCameraPtzAreaZoomFromProxy = 'requested'
  },
  [SET_CAMERA_PTZ_AREA_ZOOM_SUCCESS]: function (state) {
    state.status.setCameraPtzAreaZoomFromProxy = 'successful'
  },
  [SET_CAMERA_PTZ_AREA_ZOOM_FAILURE]: function (state) {
    state.status.setCameraPtzAreaZoomFromProxy = 'failed'
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}