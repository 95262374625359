export default {
  parseIncidentReports: function (data) {
    function formattedEventDate(start, end) {
      try {
        if (!start || !end) return;

        const startDate = new Date(start);
        const endDate = new Date(end);
        const startY = new Date(data?.event_start_time)?.getFullYear()
        const endYear = new Date(data?.event_end_time)?.getFullYear()
        const sameYear = startY === endYear

        const startFormatted = startDate.toLocaleDateString(undefined, { 
          month: 'short', 
          day: 'numeric' 
        }).replace(/,/g, ''); // 콤마 제거

        const endOptions = { 
          month: 'short', 
          day: 'numeric' 
        };

        const endFormatted = endDate.toLocaleDateString(undefined, endOptions).replace(/,/g, ''); // 콤마 제거

        return `${startY} ${startFormatted} - ${sameYear ? '' : endYear + ' ' }${endFormatted}`;
      } 
      catch (error) {
        return `unknown date`;
      }
    }


    return {
      id: data?.id,
      name: data?.name,
      createdAt: data?.created_at,
      createdBy: data?.created_by,
      reportUrl: data?.report_url,
      eventStartTime: data?.event_start_time,
      eventEndTime: data?.event_end_time,
      formattedEventRange: formattedEventDate(data?.event_start_time, data?.event_end_time),
      startYear: new Date(data?.event_start_time)?.getFullYear() || 'unknown',
      endYear: new Date(data?.event_end_time)?.getFullYear() || 'unknown',
    }
  },
  parseIncidentReportLogs: function (data) {
    let jsonData = null
    try {jsonData = JSON.parse(data?.logs)} catch (err) {console.log(err);}

    return {
      id: data?.id,
      createdAt: data?.created_at,
      createdBy: data?.created_by,
      reportUrl: data?.report_url,
      eventStartTime: data?.event_start_time,
      eventEndTime: data?.event_end_time,
      formattedEventRange: formattedEventDate(data?.event_start_time, data?.event_end_time),
      startYear: new Date(data?.event_start_time)?.getFullYear() || 'unknown',
    }
  },
}