import Vue from 'vue'
import api from '@/apis/siteProxyRecording'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,

  // GETs
  GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE,

  SET_RECORDING_TIME_INTERVAL,
  SET_ONVIF_PLAYBACK_INFO,
  SET_PLAYBACK_JUMP_TO,
} from '@/store/mutation-types'


function isOnvifDataGivesSuccess(data) {
  let result = true
  
  if (data.data && 'Fault' in data.data) result = false
  if (Array.isArray(data?.errors) && data?.errors?.length) result = false
  if (!Array.isArray(data?.errors) && data?.errors) result = false
  if (data?.error) result = false;

  // 에러 알려주기.
  if (!result) console.warn('ℹ️ proxy api is succeeded but, onvif gives errors in bypassed data')

  return result
}

let initialState = {
  camerasOnvifRecordingConfig: {},
  onvifPlaybackInfo: {},
  playbckRecordingIntervalRange: 10,
  playbackJumpTo: {},
  status: {
    camerasOnvifRecordingConfig: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  camerasOnvifRecordingConfig: function (state) {
    return state.camerasOnvifRecordingConfig
  },
  cameraOnvifRecordingConfig: function (state, getters) {
    return function (deviceId) {
      return getters.camerasOnvifRecordingConfig[deviceId]
    }
  },
  playbckRecordingIntervalRange: function (state) {
    return state.playbckRecordingIntervalRange
  },
  onvifPlaybackInfo: function (state) {
    return state.onvifPlaybackInfo
  },
  playbackJumpTo: function (state) {
    return state.playbackJumpTo
  }
}

// actions
const actions = {
  getSiteOnvifRecordingsConfigFromProxy: function ({commit}, {siteId, bridgeId, channel, deviceId}) {
    commit(GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifRecordingConfigFromProxy({siteId, bridgeId, channel}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) commit(GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE)
          else commit(GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_SUCCESS, {data, deviceId})

          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_REQUEST]: function (state) {
    state.status.camerasOnvifRecordingConfig = 'requested'
  },
  [GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    if (!deviceId) return
    if (!data?.data) return
    
    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasOnvifRecordingConfig[deviceId]) {
      Vue.set(state.camerasOnvifRecordingConfig, deviceId, {});
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasOnvifRecordingConfig, deviceId, data?.data);

    state.status.camerasOnvifRecordingConfig = 'successful'
  },
  [GET_SITE_ONVIF_RECORDING_CONFIG_FROM_PROXY_FAILURE]: function (state) {
    state.status.camerasOnvifRecordingConfig = 'failed'
  },
  [SET_RECORDING_TIME_INTERVAL]: function (state, interval) {
    state.playbckRecordingIntervalRange = interval;
  },
  [SET_ONVIF_PLAYBACK_INFO]: function (state, data) {
    state.onvifPlaybackInfo = {...data}
  },
  [SET_PLAYBACK_JUMP_TO]: function (state, data) {
    state.playbackJumpTo = {...data}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}