import Vue                    from 'vue'
import Vuex                   from 'vuex'
import rootModule             from './rootModule'

import routeTabs              from './modules/route/tabs'

import auth                   from './modules/auth/auth'
import authorization          from './modules/auth/authorization'
import logout                 from './modules/auth/logout'

import myProfile              from './modules/general/myProfile'
import push                   from './modules/general/push'
import agreement              from './modules/general/agreement'
import cameras                from "./modules/general/cameras"

import deviceSnapshot         from './modules/device/snapshot'
import deviceEvent            from './modules/device/event'

import eventRecord            from './modules/event/record'

import dealer                 from './modules/site/dealer'
import site                   from './modules/site/site'
import device                 from './modules/site/device'
import activityLogs           from './modules/site/activityLogs'
import event                  from './modules/site/event'
import member                 from './modules/site/member'
import notificationMember     from './modules/site/notificationMember'
import delay                  from './modules/site/delay'
import arming                 from './modules/site/arming'
import armingMode             from './modules/site/armingMode'
import eventStatistics        from './modules/site/eventStatistics'
import tts                    from './modules/site/tts'
import relay                  from './modules/site/relay'
import armingSchedule         from './modules/site/armingSchedule'
import webrtc                 from './modules/site/webrtc'
import proxy                  from './modules/site/proxy'
import proxyCameraRelay       from './modules/site/proxyCameraRelay'
import proxySpeaker           from './modules/site/proxySpeaker'
import proxyPtz               from './modules/site/proxyPtz'
import proxyRecording         from './modules/site/proxyRecording'
import incidentReports         from './modules/site/incidentReports'

import modal                  from './modules/control/modal'
import eventVideoViewer       from './modules/control/eventVideoViewer'
import native                 from './modules/control/native'
import support                from './modules/control/support'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: rootModule.state,
  actions: rootModule.actions,
  getters: rootModule.getters,
  mutations: rootModule.mutations,
  modules: {
    routeTabs,
    auth,
    authorization,
    logout,
    myProfile,
    push,
    deviceSnapshot,
    deviceEvent,
    eventRecord,
    dealer,
    site,
    device,
    activityLogs,
    event,
    member,
    notificationMember,
    delay,
    arming,
    armingMode,
    modal,
    eventVideoViewer,
    eventStatistics,
    tts,
    relay,
    agreement,
    native,
    armingSchedule,
    webrtc,
    support,
    proxy,
    proxyCameraRelay,
    proxySpeaker,
    proxyPtz,
    proxyRecording,
    cameras,
    incidentReports,
  },
  strict: debug
})
