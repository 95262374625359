import Vue from 'vue'
import api from '@/apis/siteDevice'
import parser from '@/models/siteDevice'
import {
  RESET_STATE,
  INIT_DEVICE_LIST,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  GET_SITE_DEVICE_REQUEST,
  GET_SITE_DEVICE_SUCCESS,
  GET_SITE_DEVICE_FAILURE,
  GET_SITE_DEVICES_REQUEST,
  GET_SITE_DEVICES_SUCCESS,
  GET_SITE_DEVICES_FAILURE,
  UPDATE_SITE_DEVICE_PRIVACYMODE_REQUEST,
  UPDATE_SITE_DEVICE_PRIVACYMODE_SUCCESS,
  UPDATE_SITE_DEVICE_PRIVACYMODE_FAILURE,
  UPDATE_SITE_DEVICE_DISARMED_ACTIVITY_EVENT_REQUEST,
  UPDATE_SITE_DEVICE_DISARMED_ACTIVITY_EVENT_SUCCESS,
  UPDATE_SITE_DEVICE_DISARMED_ACTIVITY_EVENT_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  // only devices in the selected site will be stored
  isInitOnce: false,
  devices: {},
  bridgeList: [],
  cameraList: [],
  cameraListOnline: {},
  cameraListOffline: {},
  status: {
    getDevice: null,
    getDevices: null,
    updateDevicePrivacyMode: null,
    updateDeviceDisarmedActivityEvent: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  initDeviceList: function (state) {
    return state.isInitOnce
  },
  siteDevice: function (state, getters) {
    return function (deviceId) {
      return getters.siteDevices[deviceId]
    }
  },
  siteDevices: function (state) {
    return state.devices
  },
  bridgeList: function (state) {
    return state.bridgeList || []
  },
  cameraList: function (state) {
    return state.cameraList || []
  },
  cameraListOnline: function (state) {
    return state.cameraListOnline || {}
  },
  cameraListOffline: function (state) {
    return state.cameraListOffline || {}
  },
  statusGetSiteDevice: function (state) {
    return state.status.getDevice
  },
  statusGetSiteDevices: function (state) {
    return state.status.getDevices
  },
  statusUpdateSiteDevicePrivacyMode: function (state) {
    return state.status.updateDevicePrivacyMode
  },
  statusUpdateSiteDeviceDisarmedActivityEvent: function (state) {
    return state.status.updateDeviceDisarmedActivityEvent
  },
  // 각 기능에 대해 support FW version 정의 해놓기. 
  supportBridgeFwVersion: function (state) {
    return {
      scheduledArming: Vue.tool.toComparableFWVersion('3.4.0.0'),
      cameraRelays: Vue.tool.toComparableFWVersion('4.4.0.0'),
      disarmedActivityEvent: Vue.tool.toComparableFWVersion('4.4.0.36'),
      playback: Vue.tool.toComparableFWVersion('5.0.2.0'),
    }
  },
  // * Member: Arming user, Arming & event user don't have "CRUD devices" permission
  // ...So can't get FW version for arming user, Arming & event user
  isFeatureSupportedWithBridgeFwVersion: function (state, getters) {
    return function (supportBridgeFwVersion) {
      let bridges = []
      
      // Parse only bridge
      for (let i = 0; i < getters.bridgeList.length; i++) {
        const bridge = getters.bridgeList[i];
        const fwVer = bridge.hardwareInfo.firmwareVersionToPadding
        
        // Push to array
        bridges.push(fwVer)
      }

      if (bridges.length < 1) return false
      let siteBridgeLowestFwVersion = bridges.reduce((prev, curr) => {
        if (prev < curr) return prev
        else return curr
      })
      
      // OUTPUT - support fw기준 크거나 같으면 true
      if (supportBridgeFwVersion <= siteBridgeLowestFwVersion) return true
      else return false
    }
  },
  isFeatureSupportedWithSpecificBridgeFwVersion: function (state, getters) {
    return function (supportBridgeFwVersion, bridgeId) {
      // Parse specific bridge
      for (let i = 0; i < getters.bridgeList.length; i++) {
        const bridge = getters.bridgeList[i];
        
        if (bridge.deviceId === bridgeId) {
          const fwVer = bridge.hardwareInfo.firmwareVersionToPadding
          
          // OUTPUT - support fw기준 크거나 같으면 true
          if (supportBridgeFwVersion <= fwVer) return true
          else return false
        }
      }
    }
  },

  

}

// actions
const actions = {
  getSiteDevice: function ({commit}, {siteId, deviceId}) {
    commit(GET_SITE_DEVICE_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteDevice({siteId, deviceId}).then(
        res => {
          let data = res.body[0]
          commit(GET_SITE_DEVICE_SUCCESS, {data})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_DEVICE_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getSiteDevices: function ({commit}, {siteId}) {
    commit(GET_SITE_DEVICES_REQUEST)
    return new Promise((resolve, reject) => {
      api.getSiteDevices({siteId}).then(
        res => {
          let data = res.body
          commit(GET_SITE_DEVICES_SUCCESS, {data})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_DEVICES_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  updateSiteDevicePrivacyMode: function ({commit}, {siteId, deviceId, isEnabled}) {  
    commit(UPDATE_SITE_DEVICE_PRIVACYMODE_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateSiteDevicePrivacyMode({siteId, deviceId, isEnabled}).then(
        () => {
          commit(UPDATE_SITE_DEVICE_PRIVACYMODE_SUCCESS)
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(UPDATE_SITE_DEVICE_PRIVACYMODE_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err)
          })
        }
      )
    })
  },
  updateSiteDeviceDisarmedActivityEvent: function ({commit}, {siteId, deviceId, isEnabled}) {  
    commit(UPDATE_SITE_DEVICE_DISARMED_ACTIVITY_EVENT_REQUEST)
    return new Promise((resolve, reject) => {
      api.updateSiteDeviceDisarmedActivityEvent({siteId, deviceId, isEnabled}).then(
        () => {
          commit(UPDATE_SITE_DEVICE_DISARMED_ACTIVITY_EVENT_SUCCESS)
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(UPDATE_SITE_DEVICE_DISARMED_ACTIVITY_EVENT_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err)
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [INIT_DEVICE_LIST]: function (state, isInitOnce) {
    state.isInitOnce = isInitOnce
  },
  [GET_SITE_DEVICE_REQUEST]: function (state) {
    state.status.getDevice = "requested"
  },
  [GET_SITE_DEVICE_SUCCESS]: function (state, {data}) {
    let deviceInfo = parser.parseDeviceData(data)
    if (deviceInfo.deviceId) {
      state.devices = {...state.devices, [deviceInfo.deviceId]: deviceInfo}
    }
    state.status.getDevice = "successful"
  },
  [GET_SITE_DEVICE_FAILURE]: function (state) {
    state.status.getDevice = "failed"
  },
  [GET_SITE_DEVICES_REQUEST]: function (state) {
    state.status.getDevices = "requested"
  },
  [GET_SITE_DEVICES_SUCCESS]: function (state, {data}) {
    let devices = {}
    let cameraList = []
    let bridgeList = []
    let cameraListOnline = []
    let cameraListOffline = []
    data.map(deviceData => {
      let deviceInfo = parser.parseDeviceData(deviceData)
      if (deviceInfo.deviceId) {
        devices[deviceInfo.deviceId] = deviceInfo
      }
    })
    for (let k of Object.keys(devices)) {
      const v = devices[k]

      // UPDATE - All bridgelist
      if (v.hardwareInfo.deviceTypeName === 'bridge') {
        bridgeList.push(v)
      }

      if (v.hardwareInfo.deviceTypeName != 'camera') continue
      if (!v.statusInfo.isRegistered) continue
      
      const cameraInfo = {
        deviceId: v.deviceId,
        name: v.deviceName,
        model: v.hardwareInfo.deviceModel,
        isOnline: !!v.networkInfo.isOnline,
      }

      // UPDATE - cameraListOnline, cameraListOffline
      if (v.networkInfo.isOnline) cameraListOnline.push(cameraInfo)
      else cameraListOffline.push(cameraInfo)

      // UPDATE - All cameraList
      cameraList = cameraListOnline.concat(cameraListOffline)

    }

    Vue.set(state, 'devices', devices)
    Vue.set(state, 'bridgeList', bridgeList)
    Vue.set(state, 'cameraList', cameraList)
    Vue.set(state, 'cameraListOnline', cameraListOnline)
    Vue.set(state, 'cameraListOffline', cameraListOffline)
    state.status.getDevices = "successful"
  },
  [GET_SITE_DEVICES_FAILURE]: function (state) {
    state.status.getDevices = "failed"
  },
  [UPDATE_SITE_DEVICE_PRIVACYMODE_REQUEST]: function (state) {
    state.status.updateDevicePrivacyMode = "requested"
  },
  [UPDATE_SITE_DEVICE_PRIVACYMODE_SUCCESS]: function (state) {
    state.status.updateDevicePrivacyMode = "successful"
  },
  [UPDATE_SITE_DEVICE_PRIVACYMODE_FAILURE]: function (state) {
    state.status.updateDevicePrivacyMode = "failed"
  },
  [UPDATE_SITE_DEVICE_DISARMED_ACTIVITY_EVENT_REQUEST]: function (state) {
    state.status.updateDeviceDisarmedActivityEvent = "requested"
  },
  [UPDATE_SITE_DEVICE_DISARMED_ACTIVITY_EVENT_SUCCESS]: function (state) {
    state.status.updateDeviceDisarmedActivityEvent = "successful"
  },
  [UPDATE_SITE_DEVICE_DISARMED_ACTIVITY_EVENT_FAILURE]: function (state) {
    state.status.updateDeviceDisarmedActivityEvent = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
